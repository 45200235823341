@media (max-width: 576px) {
.featured-products {
    padding: 10px 0;
    text-align: center;
  }
.featured-products h2 {
    padding: 10px 0;
    font-size: 18px;
    text-align: center;
  }
  
  .product-list {
    display: flex;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;
    margin-bottom: 30px;
    padding: 0px 5px;
  }
  
  .featured-products img {
    width: 110px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease; /* Apply transition to the entire card */
    cursor: pointer; /* Add cursor pointer to indicate clickable */
    object-fit: cover;
    border-bottom: 1px solid #ddd;
    transition: transform 0.3s ease;
  cursor: pointer; /* Add cursor pointer to indicate clickable */
  }

  .featured-products img:hover {
    transform: scale(1.03); /* Scale up on hover */
  }

  /* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1000; /* Ensure modal is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative; /* Ensure relative positioning for absolute positioning of close button */
}

.modal img {
  max-width: 100%;
  max-height: 90vh; /* Limit height to 90% of viewport height */
}

.featured-products-close-btn {
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: transparent;
  border: none;
  font-size: 22px;
  color: #333;
  cursor: pointer;
}

.featured-products-close-btn:hover {
  color: #555;
}

body.modal-open {
  overflow: hidden; /* Prevent scrolling */
}
}
@media screen and (min-width: 577px) and (max-width: 767px) {
  .featured-products {
    padding: 10px 0;
    text-align: center;
  }
.featured-products h2 {
    padding: 10px 0;
    font-size: 16px;
    text-align: center;
  }
  
  .product-list {
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
    margin-bottom: 30px;
    padding: 0px 5px;
  }
  
  .featured-products img {
    width: 150px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease; /* Apply transition to the entire card */
    cursor: pointer; /* Add cursor pointer to indicate clickable */
    object-fit: cover;
    border-bottom: 1px solid #ddd;
    transition: transform 0.3s ease;
  cursor: pointer; /* Add cursor pointer to indicate clickable */
  }

  .featured-products img:hover {
    transform: scale(1.03); /* Scale up on hover */
  }

  /* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1000; /* Ensure modal is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative; /* Ensure relative positioning for absolute positioning of close button */
}

.modal img {
  max-width: 100%;
  max-height: 90vh; /* Limit height to 90% of viewport height */
}

.featured-products-close-btn {
  position: absolute;
  top: 15px;
  right: 2px;
  background-color: transparent;
  border: none;
  font-size: 22px;
  color: #333;
  cursor: pointer;
}

.featured-products-close-btn:hover {
  color: #555;
}

body.modal-open {
  overflow: hidden; /* Prevent scrolling */
}
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
.featured-products {
    padding: 20px 0;
    text-align: center;
    
    
    
  }
.featured-products h2 {
    padding: 10px 0;
    font-size: 24px;
    text-align: center;
  }

  .product-list-header {
    font-size: 36px;
  }
  
  .product-list {
    display: flex;
    justify-content: center;
    gap: 25px;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  
  .featured-products img {
    width: 200px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease; /* Apply transition to the entire card */
    cursor: pointer; /* Add cursor pointer to indicate clickable */
    object-fit: cover;
    border-bottom: 1px solid #ddd;
    transition: transform 0.3s ease;
  cursor: pointer; /* Add cursor pointer to indicate clickable */
  }

  .featured-products img:hover {
    transform: scale(1.03); /* Scale up on hover */
  }

  /* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1000; /* Ensure modal is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative; /* Ensure relative positioning for absolute positioning of close button */
}

.modal img {
  max-width: 100%;
  max-height: 90vh; /* Limit height to 90% of viewport height */
}

.featured-products-close-btn {
  position: absolute;
  top: 20px;
  right: 25px;
  background-color: transparent;
  border: none;
  font-size: 22px;
  color: #333;
  cursor: pointer;
}

.featured-products-close-btn:hover {
  color: #555;
}

body.modal-open {
  overflow: hidden; /* Prevent scrolling */
}
}
@media screen and (min-width: 1024px) and (max-width: 1439px) {
.featured-products {
    padding: 20px 0;
    text-align: center;
    
    
    
  }
.featured-products h2 {
    padding: 10px 0;
    font-size: 28px;
    text-align: center;
  }

  .product-list-header {
    font-size: 36px;
  }
  
  .product-list {
    display: flex;
    justify-content: center;
    gap: 25px;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  
  .featured-products img {
    width: 250px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease; /* Apply transition to the entire card */
    cursor: pointer; /* Add cursor pointer to indicate clickable */
    object-fit: cover;
    border-bottom: 1px solid #ddd;
    transition: transform 0.3s ease;
  cursor: pointer; /* Add cursor pointer to indicate clickable */
  }

  .featured-products img:hover {
    transform: scale(1.03); /* Scale up on hover */
  }

  /* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1000; /* Ensure modal is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative; /* Ensure relative positioning for absolute positioning of close button */
}

.modal img {
  max-width: 100%;
  max-height: 90vh; /* Limit height to 90% of viewport height */
}

.featured-products-close-btn {
  position: absolute;
  top: 20px;
  right: 25px;
  background-color: transparent;
  border: none;
  font-size: 22px;
  color: #333;
  cursor: pointer;
}

.featured-products-close-btn:hover {
  color: #555;
}

body.modal-open {
  overflow: hidden; /* Prevent scrolling */
}
}
@media screen and (min-width: 1440px) and (max-width: 2559px) {
.featured-products {
    padding: 20px 0;
    text-align: center;
    
    
    
  }
.featured-products h2 {
    padding: 10px 0;
    font-size: 28px;
    text-align: center;
  }

  .product-list-header {
    font-size: 36px;
  }
  
  .product-list {
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  
  .featured-products img {
    width: 350px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease; /* Apply transition to the entire card */
    cursor: pointer; /* Add cursor pointer to indicate clickable */
    object-fit: cover;
    border-bottom: 1px solid #ddd;
    transition: transform 0.3s ease;
  cursor: pointer; /* Add cursor pointer to indicate clickable */
  }

  .featured-products img:hover {
    transform: scale(1.03); /* Scale up on hover */
  }

  /* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1000; /* Ensure modal is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative; /* Ensure relative positioning for absolute positioning of close button */
}

.modal img {
  max-width: 100%;
  max-height: 90vh; /* Limit height to 90% of viewport height */
}

.featured-products-close-btn {
  position: absolute;
  top: 20px;
  right: 25px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
}

.featured-products-close-btn:hover {
  color: #555;
}

body.modal-open {
  overflow: hidden; /* Prevent scrolling */
}
}
@media screen and (min-width: 2560px) {
.featured-products {
    padding: 20px 0;
    text-align: center;
    
    
    
  }
.featured-products h2 {
    padding: 10px 0;
    font-size: 28px;
    text-align: center;
  }

  .product-list-header {
    font-size: 36px;
  }
  
  .product-list {
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  
  .featured-products img {
    width: 500px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease; /* Apply transition to the entire card */
    cursor: pointer; /* Add cursor pointer to indicate clickable */
    object-fit: cover;
    border-bottom: 1px solid #ddd;
    transition: transform 0.3s ease;
  cursor: pointer; /* Add cursor pointer to indicate clickable */
  }

  .featured-products img:hover {
    transform: scale(1.03); /* Scale up on hover */
  }

  /* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1000; /* Ensure modal is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative; /* Ensure relative positioning for absolute positioning of close button */
}

.modal img {
  max-width: 100%;
  max-height: 90vh; /* Limit height to 90% of viewport height */
}

.featured-products-close-btn {
  position: absolute;
  top: 20px;
  right: 25px;
  background-color: transparent;
  border: none;
  font-size: 42px;
  color: #333;
  cursor: pointer;
}

.featured-products-close-btn:hover {
  color: #555;
}

body.modal-open {
  overflow: hidden; /* Prevent scrolling */
}
}