
@media (max-width: 576px) {
  .header {
    background-color: #fff;
    color: #333;
    padding-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .logo{
    display: flex;
    justify-content: center;
  }
  
  .logo img {
    height: 150px;
}
  
  .header nav ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    font-size: 15px;
    list-style: none;
    padding: 0;
    margin: 0;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
  }
  

  .header nav ul li a {
    color: black;
    text-decoration: none;
  }
  
  .header nav ul li a:hover {
    text-decoration: underline;
  }
}

@media screen and (min-width: 577px) and (max-width: 767px) {

  .header {
    background-color: #fff;
    color: #333;
    padding-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .logo{
    display: flex;
    justify-content: center;
  }
  
  .logo img {
    height: 200px;
}
  
  .header nav ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    font-size: 22px;
    list-style: none;
    padding: 0;
    margin: 0;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
  }
  
  .header nav ul li a {
    color: black;
    text-decoration: none;
    
  }
  
  .header nav ul li a:hover {
    text-decoration: underline;
  }
    
}
@media screen and (min-width: 768px) and (max-width: 1023px){

  .header {
      background-color: #fff;
      color: #333;
      padding-bottom: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  
    .logo{
      display: flex;
      justify-content: center;
    }
    
    .logo img {
      height: 275px;
  }
    
    .header nav ul {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 60px;
      font-size: 24px;
      list-style: none;
      padding: 0;
      margin: 0;
      font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
    }
    
    .header nav ul li a {
      color: black;
      text-decoration: none;
      
    }
    
    .header nav ul li a:hover {
      text-decoration: underline;
    }
    
}
@media screen and (min-width: 1024px) and (max-width: 1439px)
{

  .header {
      background-color: #fff;
      color: #333;
      padding-bottom: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  
    .logo{
      display: flex;
      justify-content: center;
    }
    
    .logo img {
      height: 275px;
  }
    
    .header nav ul {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 100px;
      font-size: 24px;
      list-style: none;
      padding: 0;
      margin: 0;
      font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
    }
    
    .header nav ul li a {
      color: black;
      text-decoration: none;
      
    }
    
    .header nav ul li a:hover {
      text-decoration: underline;
    }
    
}
@media screen and (min-width: 1440px) and (max-width: 2559px)
{

  .header {
      background-color: #fff;
      color: #333;
      padding-bottom: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  
    .logo{
      display: flex;
      justify-content: center;
    }
    
    .logo img {
      height: 275px;
  }
    
    .header nav ul {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 50px;
      font-size: 26px;
      list-style: none;
      padding: 0;
      margin: 0;
      font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
    }
    
    .header nav ul li a {
      color: black;
      text-decoration: none;
      
    }
    
    .header nav ul li a:hover {
      text-decoration: underline;
    }
    
}
@media screen and (min-width: 2560px)
{

  .header {
      background-color: #fff;
      color: #333;
      padding-bottom: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  
    .logo{
      display: flex;
      justify-content: center;
    }
    
    .logo img {
      height: 450px;
  }
    
    .header nav ul {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 100px;
      font-size: 42px;
      list-style: none;
      padding: 0;
      margin: 0;
      font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
    }
    
    .header nav ul li a {
      color: black;
      text-decoration: none;
      
    }
    
    .header nav ul li a:hover {
      text-decoration: underline;
    }
    
}