.footer a {
color: lightgray;
}

@media (max-width: 576px) { 

  .holdowsky {
    font-size: 8px;
  }

  .footer {
      background-color: #333;
      color: #fff;
      padding: 20px 0;
      font-size: 10px;
    }
    
    .footer-content {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    
    .footer-content p {
      margin: 0;
    }
    
    .footer nav ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    
    .footer nav ul li {
      display: inline-block;
      margin-right: 20px;
    }
    
    .footer nav ul li:last-child {
      margin-right: 0;
    }
    
    .footer nav ul li a {
      color: #fff;
      text-decoration: none;
    }
    
    .footer nav ul li a:hover {
      text-decoration: underline;
    }
}
  
@media screen and (min-width: 577px) and (max-width: 767px)
{
.holdowsky {
  font-size: 8px;
}
  .footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    flex-direction: column;
  }
  
  .footer-content p {
    margin: 0;
  }
  
  .footer nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer nav ul li {
    display: inline-block;
    margin-right: 20px;
  }
  
  .footer nav ul li:last-child {
    margin-right: 0;
  }
  
  .footer nav ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer nav ul li a:hover {
    text-decoration: underline;
  }}
@media screen and (min-width: 768px) and (max-width: 1023px)
{
  .holdowsky {
    font-size: 8px;
  }
  .footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    flex-direction: column;
  }
  
  .footer-content p {
    margin: 0;
  }
  
  .footer nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer nav ul li {
    display: inline-block;
    margin-right: 20px;
  }
  
  .footer nav ul li:last-child {
    margin-right: 0;
  }
  
  .footer nav ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer nav ul li a:hover {
    text-decoration: underline;
  }}
@media screen and (min-width: 1024px) and (max-width: 1439px)
{
  .holdowsky {
    font-size: 9px;
  }
  .footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    flex-direction: column;
  }
  
  .footer-content p {
    margin: 0;
  }
  
  .footer nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer nav ul li {
    display: inline-block;
    margin-right: 20px;
  }
  
  .footer nav ul li:last-child {
    margin-right: 0;
  }
  
  .footer nav ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer nav ul li a:hover {
    text-decoration: underline;
  }}
  
  @media screen and (min-width: 1440px) and (max-width: 2559px)
   {
    .holdowsky {
      font-size: 11px;
    }
    .footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    flex-direction: column;
  }
  
  .footer-content p {
    margin: 0;
  }
  
  .footer nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer nav ul li {
    display: inline-block;
    margin-right: 20px;
  }
  
  .footer nav ul li:last-child {
    margin-right: 0;
  }
  
  .footer nav ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer nav ul li a:hover {
    text-decoration: underline;
  }}
  
  @media screen and (min-width: 2560px) {
    .holdowsky {
      font-size: 20px;
    }

    .footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    flex-direction: column;
  }
  
  .footer-content p {
    margin: 0;
  }
  
  .footer nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer nav ul li {
    display: inline-block;
    margin-right: 20px;
  }
  
  .footer nav ul li:last-child {
    margin-right: 0;
  }
  
  .footer nav ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer nav ul li a:hover {
    text-decoration: underline;
  }}
  