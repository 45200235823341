@media (max-width: 576px) {
  .hero-section {
    background-image: url('../../public/prettyPresentBackground.jpg'); 
    background-size: 100%;
    background-position: center;
    display: flex;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    font-family: 'Playfair Display', serif;
    position: relative; /* Position the container relatively */
  }
  
  .hero-content {
    max-width: 45%;
    position: relative; /* Position the content container relatively */
  }
  
  .hero-section h1 {
    font-size: 18px;
  }
  
  .hero-section p {
    font-size: 12px;
    /* margin-bottom: 15px; */
  }
  
  .cta-btn {
    display: inline-block;
    padding: 3px 6px;
    margin-bottom: 10px;
    background-color: #ff7b54;
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .cta-btn:hover {
    background-color: #ff4c00;
  }
}
@media screen and (min-width: 577px) and (max-width: 767px) {
  .hero-section {
    background-image: url('../../public/prettyPresentBackground.jpg'); 
    background-size: 100%;
    background-position: center;
    display: flex;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    font-family: 'Playfair Display', serif;
    position: relative; /* Position the container relatively */
  }
  
  .hero-content {
    max-width: 40%;
    position: relative; /* Position the content container relatively */
  }
  
  .hero-section h1 {
    font-size: 20px;
  }
  
  .hero-section p {
    font-size: 14px;
  }
  
  .cta-btn {
    display: inline-block;
    padding: 3px 6px;
    margin-bottom: 10px;
    background-color: #ff7b54;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .cta-btn:hover {
    background-color: #ff4c00;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .hero-section {
    background-image: url('../../public/prettyPresentBackground.jpg'); 
    background-size: 100%;
    background-position: center;
    display: flex;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    font-family: 'Playfair Display', serif;
    position: relative; /* Position the container relatively */
  }
  
  .hero-content {
    max-width: 40%;
    position: relative; /* Position the content container relatively */
  }
  
  .hero-section h1 {
    font-size: 24px;
  }
  
  .hero-section p {
    font-size: 16px;
  }
  
  .cta-btn {
    display: inline-block;
    padding: 3px 6px;
    margin-bottom: 10px;
    background-color: #ff7b54;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .cta-btn:hover {
    background-color: #ff4c00;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .hero-section {
    background-image: url('../../public/prettyPresentBackground.jpg'); 
    background-size: 100%;
    background-position: center;
    display: flex;
    background-repeat: no-repeat;
    justify-content: center;
    height: 200px;
    align-items: center;
    text-align: center;
    color: #fff;
    font-family: 'Playfair Display', serif;
    position: relative; /* Position the container relatively */
  }
  
  .hero-content {
    max-width: 40%;
    position: relative; /* Position the content container relatively */
  }
  
  .hero-section h1 {
    font-size: 24px;
  }
  
  .hero-section p {
    font-size: 16px;
  }
  
  .cta-btn {
    display: inline-block;
    padding: 3px 6px;
    margin-bottom: 10px;
    background-color: #ff7b54;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .cta-btn:hover {
    background-color: #ff4c00;
  }
}
@media screen and (min-width: 1440px) and (max-width: 2559px) {
  .hero-section {
    background-image: url('../../public/prettyPresentBackground.jpg'); 
    background-size: cover;
    height: 250px;
    background-position: center;
    display: flex;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    font-family: 'Playfair Display', serif;
    position: relative; /* Position the container relatively */
  }
  
  .hero-content {
    max-width: 45%;
    position: relative; /* Position the content container relatively */
  }
  
  .hero-section h1 {
    font-size: 26px;
  }
  
  .hero-section p {
    font-size: 20px;
  }
  
  .cta-btn {
    display: inline-block;
    padding: 3px 6px;
    margin-bottom: 10px;
    background-color: #ff7b54;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .cta-btn:hover {
    background-color: #ff4c00;
  }
}
@media screen and (min-width: 2560px) {
  .hero-section {
    background-image: url('../../public/prettyPresentBackground.jpg'); 
    background-size: 100%;
    background-position: center;
    display: flex;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 400px;
    color: #fff;
    font-family: 'Playfair Display', serif;
    position: relative; /* Position the container relatively */
  }
  
  .hero-content {
    max-width: 40%;
    position: relative; /* Position the content container relatively */
  }
  
  .hero-section h1 {
    font-size: 42px;
  }
  
  .hero-section p {
    font-size: 28px;
  }
  
  .cta-btn {
    display: inline-block;
    padding: 3px 6px;
    margin-bottom: 20px;
    background-color: #ff7b54;
    color: #fff;
    text-decoration: none;
    font-size: 26px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .cta-btn:hover {
    background-color: #ff4c00;
  }
}