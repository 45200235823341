/* Catalog.css */

@media (max-width: 576px)
{
  .catalog {
  padding: 20px 5px;

}

.present-images {
  display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
}

.present-images img {
  width: 40%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer; /* Add cursor pointer to indicate clickable */
}

.present-images img:hover {
  transform: scale(1.03);
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1000; /* Ensure modal is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative; /* Ensure relative positioning for absolute positioning of close button */
}

.modal img {
  max-width: 100%;
  max-height: 90vh; /* Limit height to 90% of viewport height */
}

.catalog-close-btn {
  position: absolute;
  top: 5px;
  right: 2px;
  background-color: transparent;
  border: none;
  font-size: 22px;
  color: #333;
  cursor: pointer;
}

.catalog-close-btn:hover {
  color: #555;
}

body.modal-open {
  overflow: hidden; /* Prevent scrolling */
}}

@media screen and (min-width: 577px) and (max-width: 767px)
{
  .catalog {
  padding: 20px 5px;

}

.present-images {
  display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
}

.present-images img {
  width: 40%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer; /* Add cursor pointer to indicate clickable */
}

.present-images img:hover {
  transform: scale(1.03);
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1000; /* Ensure modal is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative; /* Ensure relative positioning for absolute positioning of close button */
}

.modal img {
  max-width: 100%;
  max-height: 90vh; /* Limit height to 90% of viewport height */
}

.catalog-close-btn {
  position: absolute;
  top: 5px;
  right: 2px;
  background-color: transparent;
  border: none;
  font-size: 22px;
  color: #333;
  cursor: pointer;
}

.catalog-close-btn:hover {
  color: #555;
}

body.modal-open {
  overflow: hidden; /* Prevent scrolling */
}}
@media screen and (min-width: 768px) and (max-width: 1023px)
{
  .catalog {
  padding: 20px 5px;

}

.present-images {
  display: flex;
    justify-content: center;
    gap: 25px;
    flex-wrap: wrap;
}

.present-images img {
  width: 45%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer; /* Add cursor pointer to indicate clickable */
}

.present-images img:hover {
  transform: scale(1.03);
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1000; /* Ensure modal is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative; /* Ensure relative positioning for absolute positioning of close button */
}

.modal img {
  max-width: 100%;
  max-height: 90vh; /* Limit height to 90% of viewport height */
}

.catalog-close-btn {
  position: absolute;
  top: 5px;
  right: 2px;
  background-color: transparent;
  border: none;
  font-size: 22px;
  color: #333;
  cursor: pointer;
}

.catalog-close-btn:hover {
  color: #555;
}

body.modal-open {
  overflow: hidden; /* Prevent scrolling */
}}

@media screen and (min-width: 1024px) and (max-width: 1439px)
{
  .catalog {
  padding: 20px 5px;

}

.present-images {
  display: flex;
    justify-content: center;
    gap: 25px;
    flex-wrap: wrap;
}

.present-images img {
  width: 30%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer; /* Add cursor pointer to indicate clickable */
}

.present-images img:hover {
  transform: scale(1.03);
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1000; /* Ensure modal is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative; /* Ensure relative positioning for absolute positioning of close button */
}

.modal img {
  max-width: 100%;
  max-height: 90vh; /* Limit height to 90% of viewport height */
}

.catalog-close-btn {
  position: absolute;
  top: 5px;
  right: 2px;
  background-color: transparent;
  border: none;
  font-size: 22px;
  color: #333;
  cursor: pointer;
}

.catalog-close-btn:hover {
  color: #555;
}

body.modal-open {
  overflow: hidden; /* Prevent scrolling */
}}

@media screen and (min-width: 1440px) and (max-width: 2559px)
{
  .catalog {
  padding: 20px 5px;

}

.present-images {
  display: flex;
    justify-content: center;
    gap: 25px;
    flex-wrap: wrap;
}

.present-images img {
  width: 30%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer; /* Add cursor pointer to indicate clickable */
}

.present-images img:hover {
  transform: scale(1.03);
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1000; /* Ensure modal is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative; /* Ensure relative positioning for absolute positioning of close button */
}

.modal img {
  max-width: 100%;
  max-height: 90vh; /* Limit height to 90% of viewport height */
}

.catalog-close-btn {
  position: absolute;
  top: 5px;
  right: 2px;
  background-color: transparent;
  border: none;
  font-size: 22px;
  color: #333;
  cursor: pointer;
}

.catalog-close-btn:hover {
  color: #555;
}

body.modal-open {
  overflow: hidden; /* Prevent scrolling */
}}

@media screen and (min-width: 2560px)
{
  .catalog {
  padding: 20px 5px;

}

.present-images {
  display: flex;
    justify-content: center;
    gap: 25px;
    flex-wrap: wrap;
}

.present-images img {
  width: 30%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer; /* Add cursor pointer to indicate clickable */
}

.present-images img:hover {
  transform: scale(1.03);
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1000; /* Ensure modal is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative; /* Ensure relative positioning for absolute positioning of close button */
}

.modal img {
  max-width: 100%;
  max-height: 90vh; /* Limit height to 90% of viewport height */
}

.catalog-close-btn {
  position: absolute;
  top: 5px;
  right: 2px;
  background-color: transparent;
  border: none;
  font-size: 22px;
  color: #333;
  cursor: pointer;
}

.catalog-close-btn:hover {
  color: #555;
}

body.modal-open {
  overflow: hidden; /* Prevent scrolling */
}}

